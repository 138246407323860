import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetState, useStateIsDefined, useWindowSizeTracker } from '@stimcar/libs-uikernel';
import type { MarketplaceAppProps } from '../../App.js';
import { ContactSuccessNotification } from '../../components/contact/ContactSuccessNotification.js';
import { useOnNavigation } from '../../hooks/useOnNavigation.js';
import { UnexpectedError } from '../../UnexpectedError.js';
import { Footer } from './Footer.js';
import { NavBar } from './NavBar.js';

export function MarketplaceAppLayout({ $gs }: MarketplaceAppProps): JSX.Element {
  // React to navigation events
  useOnNavigation($gs);
  // Track the window size
  useWindowSizeTracker($gs);

  const hasError = useStateIsDefined($gs.$error);
  const bulmaMode = useGetState($gs.$window.$bulmaMode);
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  return (
    <>
      <NavBar $gs={$gs} />
      <ContactSuccessNotification $gs={$gs} />
      <div className={bulmaTheme === 'light' ? 'has-lightgrey-background' : 'has-dark-background'}>
        <div
          className={`container is-max-widescreen mt-5 mb-2${bulmaMode === 'Mobile' || bulmaMode === 'Tablet' ? ' px-3' : ''}`}
        >
          {!hasError ? <Outlet /> : <UnexpectedError $gs={$gs} />}
          <Footer $gs={$gs} />
        </div>
      </div>
    </>
  );
}
