import type { PriceableDefect, SelectedRefurbishingOptions } from '../typings/marketplace.js';

export function getTotalRefurbishingOptionsPrice(
  defects: readonly PriceableDefect[],
  selectedRefurbishingOptions: SelectedRefurbishingOptions
): number {
  const { defectsIdsToBeFullyRefurbished, defectsIdsToBePartiallyRefurbished } =
    selectedRefurbishingOptions;

  const defectsPrices = defects.map(
    ({ id, fullyRefurbishingOptionPrice, partiallyRefurbishingOptionPrice }) => {
      if (defectsIdsToBeFullyRefurbished.includes(id)) {
        return fullyRefurbishingOptionPrice ?? 0;
      }
      if (defectsIdsToBePartiallyRefurbished.includes(id)) {
        return partiallyRefurbishingOptionPrice ?? 0;
      }
      return 0;
    }
  );

  // Calculate sum
  return defectsPrices.reduce((sum, price) => sum + price, 0);
}
