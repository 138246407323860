import {
  isTruthyAndNotEmpty,
  LAN_API_ROUTE_PREFIX,
  WAN_API_ROUTE_PREFIX,
} from '@stimcar/libs-kernel';
import type { StorageCategories } from './typings/general.js';
import type { ChannelType } from './typings/paymentOrder.js';
import type { RepositoryEntities } from './typings/repository.js';
import type { ShareType } from './typings/share.js';
import type { KnownKeysOf } from './typings/typescript.js';
import { CommonRoutes } from './globalConstants.js';

export const SUBCONTRACTOR_ENTRY_POINT = 'sst';
export const CORE_ENTRY_POINT = 'main';
export const DISPLAY_ENTRY_POINT = 'display';
export const CONTAINER_ENTRY_POINT = 'local-network-container';

export const ENTRYPOINT_IDS = [
  SUBCONTRACTOR_ENTRY_POINT,
  CORE_ENTRY_POINT,
  DISPLAY_ENTRY_POINT,
  CONTAINER_ENTRY_POINT,
] as const;

export type EntryPointId = (typeof ENTRYPOINT_IDS)[number];

export interface EntryPointInfo {
  readonly companyId: string;
  readonly entryPointId: EntryPointId;
}

export const STIMCAR_FIRM = 'stimcar';

export type AttachmentThumbnailType = 'cover' | 'contain' | 'inside' | 'outside';

export const ATTACHMENT_TEMPORARY_FOLDER_PREFIX = 'temp_';

export const URL_LIST_ELEMENTS_SEPARATOR = ';';

export const SubcontractorBackendRoutes = {
  GET_AVAILABLE_SUBCONTRACTOR_KANBANS: (siteId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanbans/available`,
  GET_SUBCONTRACTOR_AVAILABLE_SITES: `${WAN_API_ROUTE_PREFIX}/subcontractor/sites`,
  GET_SUBCONTRACTOR_KANBAN: (siteId: string, kanbanId: string, standId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/infos/${standId}`,
  GET_SUBCONTRACTOR_KANBAN_FOR_POST: (
    siteId: string,
    kanbanId: string,
    standId: string,
    implantationId: string,
    implantationCategory: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/infos/${standId}/${implantationId}/${implantationCategory}`,
  SUBCONTRACTOR_CHANGE_OPERATION_MESSAGE: (
    siteId: string,
    kanbanId: string,
    messageId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/message/${messageId}/change`,
  SUBCONTRACTOR_DELETE_OPERATION_MESSAGE: (
    siteId: string,
    kanbanId: string,
    messageId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/message/${messageId}/delete`,
  SUBCONTRACTOR_ADD_OPERATION_MESSAGE: (
    siteId: string,
    kanbanId: string,
    packageDealId: string,
    operationId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/message/add/${packageDealId}/${operationId}`,
  HANDLE_WORKSHOP_SUBCONTRACTOR_KANBAN: (
    siteId: string,
    kanbanId: string,
    standId: string,
    implantationId: string,
    categoryId: string,
    postId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/handle/${standId}/${implantationId}/${categoryId}/${postId}`,
  STOP_HANDLE_ON_WORKSHOP_SUBCONTRACTOR_KANBAN: (
    siteId: string,
    kanbanId: string,
    standId: string,
    implantationId: string,
    categoryId: string,
    postId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/handle/${standId}/${implantationId}/${categoryId}/${postId}/stop`,
  SUBCONTRACTOR_EJECT_KANBAN_FROM_POST: (
    siteId: string,
    kanbanId: string,
    standId: string,
    implantationId: string,
    categoryId: string,
    postId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/handle/${standId}/${implantationId}/${categoryId}/${postId}/eject`,
  TOGGLE_SUBCONTRACTOR_WORKSHOP_POST_OPERATION_STATUS: (
    siteId: string,
    standId: string,
    implantationId: string,
    categoryId: string,
    postId: string,
    kanbanId: string,
    packageDealId: string,
    operationId: string,
    selectedFiles?: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/operation/${standId}/${implantationId}/${categoryId}/${postId}/toggle/${packageDealId}/${operationId}${selectedFiles ? `?selectedFiles=${selectedFiles}` : ''}`,
  TOGGLE_SUBCONTRACTOR_STAND_OPERATION_STATUS: (
    siteId: string,
    standId: string,
    kanbanId: string,
    packageDealId: string,
    operationId: string,
    selectedFiles?: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/${siteId}/kanban/${kanbanId}/operation/${standId}/toggle/${packageDealId}/${operationId}${selectedFiles ? `?selectedFiles=${selectedFiles}` : ''}`,
  SUBCONTRACTOR_ATTACHMENT: (
    siteId: string,
    category: StorageCategories,
    objectId: string,
    folder: string,
    filename: string
  ): string => {
    return `${WAN_API_ROUTE_PREFIX}/subcontractor/attachment/${siteId}/${category}/${objectId}/${folder}/raw/${encodeURI(filename)}`;
  },
  SUBCONTRACTOR_ATTACHMENT_THUMBNAIL: (
    siteId: string,
    category: StorageCategories,
    objectId: string,
    folder: string,
    mode: AttachmentThumbnailType,
    size: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/attachment/${siteId}/${category}/${objectId}/${folder}/thumbnail/${mode}/${size}/${encodeURI(
      filename
    )}`,
  SUBCONTRACTOR_ATTACHMENT_FOLDER: (
    siteId: string,
    category: StorageCategories,
    objectId: string,
    folder: string,
    override = false
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/subcontractor/attachment/${siteId}/${category}/${objectId}/${folder}${override ? '?override=true' : ''}`,
};

export const COPY_PACKAGE_DEAL_DESC_MODES = [
  'allDatabases',
  'sameDatabaseOnOtherSites',
  'someDatabasesOnSomeSites',
] as const;
export type CopyPackageDealDescMode = (typeof COPY_PACKAGE_DEAL_DESC_MODES)[number];

export const MARKETPLACE_DOC_TYPE_BUY = 'buy';
export const MARKETPLACE_DOC_TYPE_SALE = 'sale';
export const MARKETPLACE_DOC_TYPE_WORK = 'work';
export const MARKETPLACE_DOC_TYPE_STIMER = 'stimer';
export const MARKETPLACE_DOC_TYPES = [
  MARKETPLACE_DOC_TYPE_BUY,
  MARKETPLACE_DOC_TYPE_SALE,
  MARKETPLACE_DOC_TYPE_WORK,
  MARKETPLACE_DOC_TYPE_STIMER,
];
export type MarketplaceDocType = (typeof MARKETPLACE_DOC_TYPES)[number];

export const CoreBackendRoutes = {
  ...CommonRoutes,
  NOTIFY: `${WAN_API_ROUTE_PREFIX}/notify`,
  GET_ALL_SITES: `${WAN_API_ROUTE_PREFIX}/allSites`,
  GET_ALL_SUB_APPLICATIONS: `${WAN_API_ROUTE_PREFIX}/subApplications`,
  SITE_CONFIGURATION: `${WAN_API_ROUTE_PREFIX}/site/configuration`,
  SITE_SHIFT_CONFIGURATION: `${WAN_API_ROUTE_PREFIX}/site/shiftConfiguration`,
  KANBAN_WORKFLOWS_STATE_SNAPSHOT: `${WAN_API_ROUTE_PREFIX}/workflows/stateSnapshot`,

  LIST_ARCHIVED_KANBAN_SUMMARIES: (
    searchFilter: string | readonly string[],
    offset?: number
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/archived/list/${typeof searchFilter === 'string' ? searchFilter : searchFilter.join(',')}${offset ? `?offset=${offset}` : ''}`,
  FIND_EXISTING_KANBAN_SUMMARIES_FOR_LICENSE: (license: string): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/find_by_license/${license}`,
  KANBAN: (id: string): string => `${WAN_API_ROUTE_PREFIX}/kanbans/entity/${id}`,
  ENGAGE_KANBAN: (kanbanId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/entity/${kanbanId}/engage`,
  SEND_KANBAN_CUSTOMER_SIDE_VALIDATION_CODE: (shareId: string, validationType: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/sendActionValidationCode/${validationType}`,
  CUSTOMER_SIDE_ENGAGE_KANBAN: (shareId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/engage`,
  CUSTOMER_SIDE_UPDATE_PACKAGE_DEAL_EXPRESSIONS_COMPUTATIONS: (shareId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/updatePackageDeals`,
  CUSTOMER_SIDE_DELIVER_KANBAN: (shareId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/deliver`,
  CUSTOMER_SIDE_TOGGLE_PACKAGE_DEAL_STATUS: (
    shareId: string,
    packageDealId: string,
    newStatus: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/packageDeals/${packageDealId}/status/${newStatus}`,
  BROWSER_INFOS: (siteId: string, label: string): string =>
    `${WAN_API_ROUTE_PREFIX}/browser/${siteId}/${label}`,
  BROWSERS: (siteId: string): string => `${WAN_API_ROUTE_PREFIX}/browsers/${siteId}`,

  REPOSITORY_DOWNLOAD_EXCEL: (
    entityName: KnownKeysOf<RepositoryEntities>,
    selectedPackageDealDatabase: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/${selectedPackageDealDatabase}/download`,
  REPOSITORY_UPLOAD_EXCEL: (entityName: KnownKeysOf<RepositoryEntities>): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/upload`,
  KANBAN_CANCELLATION: (entityName: KnownKeysOf<RepositoryEntities>): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/kanbanCancellation`,
  ATTACHMENT_FOLDER: (
    category: StorageCategories,
    objectId: string,
    folder: string,
    override = false
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/${category}/${objectId}/${folder}${override ? '?override=true' : ''}`,
  ATTACHMENT_STANDARD_PICTURE_FOLDER: (
    kanbanId: string,
    folder: string,
    override = false,
    isImprovedImage: boolean = false
  ): string => {
    let queryString = '';

    if (override) {
      queryString += '?override=true';
    }

    if (isImprovedImage) {
      queryString += `${isTruthyAndNotEmpty(queryString) ? '&' : '?'}isImprovedImage=true`;
    }
    return `${WAN_API_ROUTE_PREFIX}/standardPicture/${kanbanId}/${folder}${queryString}`;
  },
  ZIPPED_ATTACHMENT_STANDARD_PICTURE_FOLDER: (kanbanId: string, folder: string): string =>
    `${WAN_API_ROUTE_PREFIX}/standardPicture/${kanbanId}/${folder}/zipped`,
  ZIPPED_ATTACHMENT_FOLDER: (
    category: StorageCategories,
    objectId: string,
    folder: string
  ): string => `${WAN_API_ROUTE_PREFIX}/attachment/${category}/${objectId}/${folder}/zipped`,
  /**
   * The endpoint to load an attachment.
   * @category The category of attachment
   * @objectId The object ID for which we want to get attachments
   * @folder The folder of the attachment (i.e. expertise)
   * @filename The name of the attachment
   */
  ATTACHMENT: (
    category: StorageCategories,
    objectId: string,
    folder: string,
    filename: string
  ): string => {
    return `${WAN_API_ROUTE_PREFIX}/attachment/${category}/${objectId}/${folder}/raw/${encodeURI(filename)}`;
  },
  /**
   * The endpoint to load an attachment thumbnail.
   * @category The category of attachment
   * @objectId The object ID for which we want to get attachments
   * @folder The folder of the attachment (i.e. expertise)
   * @mode The mode used to compute the thumbnail
   * @size The size of the thumbnail
   * @filename The name of the attachment
   */
  ATTACHMENT_THUMBNAIL: (
    category: StorageCategories,
    objectId: string,
    folder: string,
    mode: AttachmentThumbnailType,
    size: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/${category}/${objectId}/${folder}/thumbnail/${mode}/${size}/${encodeURI(
      filename
    )}`,
  CONVERT_ATTACHMENTS_TO_PDF: (
    category: StorageCategories,
    objectId: string,
    targetFolder: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/attachment/${category}/${objectId}/${targetFolder}/convert-to-pdf`,
  /**
   * The endpoint to load an attachment which is a picture from the standard set of pictures
   * For such pictures, we will look for a '<filename>_improved.webp' picture first
   * If there is none, we will look for a '<filename>_original.webp' picture
   * If none, we send an error back
   * @kanbanId The kanban ID for which we want to get attachments
   * @folder The folder of the attachment (i.e. expertise)
   * @filename The name of the attachment
   */
  ATTACHMENT_STANDARD_PICTURE: (
    kanbanId: string,
    folder: string,
    filename: string,
    isImprovedImage: boolean = false
  ): string => {
    return `${WAN_API_ROUTE_PREFIX}/standardPicture/${kanbanId}/${folder}/raw/${encodeURI(filename)}${isImprovedImage ? '/?isImprovedImage=true' : ''}`;
  },
  /**
   * The endpoint to load a standard picture's thumbnail.
   * @kanbanId The kanban ID for which we want to get attachments
   * @folder The folder of the attachment (i.e. expertise)
   * @mode The mode used to compute the thumbnail
   * @size The size of the thumbnail
   * @filename The name of the attachment
   */
  ATTACHMENT_STANDARD_PICTURE_THUMBNAIL: (
    kanbanId: string,
    folder: string,
    mode: AttachmentThumbnailType,
    size: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/standardPicture/${kanbanId}/${folder}/thumbnail/${mode}/${size}/${encodeURI(
      filename
    )}`,
  NEW_SHARE_ID: (type: ShareType, objectId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/new/${type}/${objectId}`,
  SHARE_CONTEXT: (shareId: string): string => `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}`,
  RELOAD_SHARED_OBJECT: (shareId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/share/context/${shareId}/reload`,
  SHARED_ZIPPED_ATTACHMENT_FOLDER: (
    shareId: string,
    category: StorageCategories,
    folder: string
  ): string => `${WAN_API_ROUTE_PREFIX}/share/attachment/${shareId}/${category}/${folder}/zipped`,
  SHARED_ATTACHMENT_FOLDER: (
    shareId: string,
    category: StorageCategories,
    folder: string
  ): string => `${WAN_API_ROUTE_PREFIX}/share/attachment/${shareId}/${category}/${folder}`,
  SHARED_ATTACHMENT: (
    shareId: string,
    category: StorageCategories,
    folder: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/share/attachment/${shareId}/${category}/${folder}/raw/${encodeURI(filename)}`,
  SHARED_ATTACHMENT_THUMBNAIL: (
    shareId: string,
    category: StorageCategories,
    folder: string,
    mode: AttachmentThumbnailType,
    size: string,
    filename: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/share/attachment/${shareId}/${category}/${folder}/thumbnail/${mode}/${size}/${encodeURI(
      filename
    )}`,
  GET_USERS: `${WAN_API_ROUTE_PREFIX}/users/list`,
  GET_USERS_FOR_WORKSHOP: `${WAN_API_ROUTE_PREFIX}/usersForWorkshop/list`,
  CREATE_USER: `${WAN_API_ROUTE_PREFIX}/user/create`,
  USER_EXISTS: (userId: string): string => `${WAN_API_ROUTE_PREFIX}/user/${userId}/exist`,
  GET_ALL_AVAILABLE_USER_PROFILES: `${WAN_API_ROUTE_PREFIX}/user/profile/list`,
  UPDATE_PASSWORD: (userId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/user/${userId}/updatePassword`,
  UPDATE_OWN_PASSWORD: `${WAN_API_ROUTE_PREFIX}/user/updateOwnPassword`,
  IS_SUPER_ADMIN_PROFILE: `${WAN_API_ROUTE_PREFIX}/user/isSuperAdmin`,
  UPDATE_USER_INFOS: (userId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/user/${userId}/updateUserInfos`,
  GET_OPERATIONS_PRE_ALLOCATION: (
    standId: string,
    implantationId: string,
    firstCategoryId: string,
    kanbanId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/preAllocation/${standId}/${implantationId}/${firstCategoryId}/${kanbanId}`,
  UPDATE_OPERATIONS_PRE_ALLOCATION_INFOS: (implantationId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/preAllocation/${implantationId}/update`,
  INVOICE_GET_AS_JSON: (invoiceRefOrId: string | number): string =>
    `${WAN_API_ROUTE_PREFIX}/invoice/${invoiceRefOrId}`,
  REFUND_INVOICE: `${WAN_API_ROUTE_PREFIX}/invoice/refund`,
  INVOICE_REFERENCE: `${WAN_API_ROUTE_PREFIX}/invoice/reference`,
  DELETE_INVOICE_INFO: `${WAN_API_ROUTE_PREFIX}/invoice/info/delete`,
  EXPORT_MARKETPLACE_KANBANS_INVOICES: (
    startTimestamp: string | number,
    endTimestamp: string | number
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/invoice/export/marketplace/invoices/start/${startTimestamp}/end/${endTimestamp}`,
  EXPORT_MARKETPLACE_KANBANS_REVENUE: (
    startTimestamp: string | number,
    endTimestamp: string | number
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/invoice/export/marketplace/revenue/start/${startTimestamp}/end/${endTimestamp}`,
  SEND_INVOICES_REMINDER: (
    reminderTimestamp: string | number,
    sendEmails: boolean = false
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/accounting/send/invoicesReminder/date/${reminderTimestamp}${sendEmails ? '?sendEmails=true' : ''}`,
  SEND_INVOICE_MAIL: (kanbanId: string, purchaseOrderId: string, invoiceInfoId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/invoice/${kanbanId}/${purchaseOrderId}/${invoiceInfoId}/send`,
  FIND_EXISTING_KANBANS_BY_ORIGIN_SOURCE_AND_ID: (originSource: string, originId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/findByOriginSourceAndId/${originSource}/${originId}`,
  FIND_NON_CLOSED_KANBANS_BY_ORIGIN_ON_SITE: (
    originSource: string,
    originId: string,
    companyId: string,
    siteId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/kanbans/findClosedKanbansByOriginOnSite/${originSource}/${originId}/${companyId}/${siteId}`,
  GET_ALL_SCHEDULED_TASKS: `${WAN_API_ROUTE_PREFIX}/scheduledTask/list`,
  GET_ACTIVE_SCHEDULED_TASKS: `${WAN_API_ROUTE_PREFIX}/scheduledTask/activeList`,
  CREATE_SCHEDULED_TASK: `${WAN_API_ROUTE_PREFIX}/scheduledTask/create`,
  UPDATE_SCHEDULED_TASK: (taskId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/scheduledTask/${taskId}/update`,
  DELETE_SCHEDULED_TASK: (taskId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/scheduledTask/${taskId}/delete`,
  GET_ALL_FULL_CONTRACTS: `${WAN_API_ROUTE_PREFIX}/contract/list`,
  GET_ALL_UI_CONTRACTS: `${WAN_API_ROUTE_PREFIX}/contract/uiList`,
  CREATE_CONTRACT: `${WAN_API_ROUTE_PREFIX}/contract/create`,
  UPDATE_CONTRACT: (contractCode: string): string =>
    `${WAN_API_ROUTE_PREFIX}/contract/${contractCode}/update`,
  DELETE_CONTRACT: (contractCode: string): string =>
    `${WAN_API_ROUTE_PREFIX}/contract/${contractCode}/delete`,
  DEV_DUMP: `${WAN_API_ROUTE_PREFIX}/dev/dump`,
  GET_ALL_SPAREPARTS_PROVIDERS: `${WAN_API_ROUTE_PREFIX}/sparePartsProvider/list`,
  CREATE_SPAREPARTS_PROVIDER: `${WAN_API_ROUTE_PREFIX}/sparePartsProvider/create`,
  UPDATE_SPAREPARTS_PROVIDER: (providerId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/sparePartsProvider/${providerId}/update`,
  DELETE_SPAREPARTS_PROVIDER: (providerId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/sparePartsProvider/${providerId}/delete`,
  MATTERMOST_LOGGER: `${WAN_API_ROUTE_PREFIX}/mattermost/logs`,
  LAN_MATTERMOST_LOGGER: `${LAN_API_ROUTE_PREFIX}/mattermost/logs`,
  SHIFT_PROGRESS_BI_STATS: `${WAN_API_ROUTE_PREFIX}/bi/shiftProgressStats`,
  CONTRACTS_MONTHLY_BI_STATS: `${WAN_API_ROUTE_PREFIX}/bi/contractsMonthlyStats`,
  COPY_PACKAGE_DEAL_DESC: (packageDealDescId: string, mode: CopyPackageDealDescMode): string =>
    `${WAN_API_ROUTE_PREFIX}/packageDealDesc/copy/${packageDealDescId}/${mode}`,
  ...SubcontractorBackendRoutes,
  PREDICT_PACKAGE_DEALS: `${WAN_API_ROUTE_PREFIX}/predictiveai/packageDeals/predict`,
  GET_KANBANS_DATA_FOR_MODEL_TRAINING: (sequenceId: string): string =>
    `${LAN_API_ROUTE_PREFIX}/predictiveai/kanbans/${sequenceId}`,
  CREATE_MARKETPLACE_OVERVIEW_DOC: (kanbanId: string, docType: MarketplaceDocType): string =>
    `${WAN_API_ROUTE_PREFIX}/marketplace/${kanbanId}/${docType}/create`,
  CREATE_PAYMENT_ORDER: (
    kanbanId: string,
    purchaseOrderId: string,
    channelType: ChannelType,
    invoiceInfoId: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/paymentorder/${kanbanId}/${purchaseOrderId}/${channelType}/${invoiceInfoId}/create`,
  INSTANT_PAYMENT_NOTIFICATION: `${WAN_API_ROUTE_PREFIX}/paymentorder/ipn`,
  RECOGNIZE_PLATES_FROM_IMAGE: `${WAN_API_ROUTE_PREFIX}/recognizePlateFromImage`,
};

export const CoreFrontendRoutes = {
  SHARE: (shareId: string, defaultTab?: string): string => {
    const base = `/share/${shareId}`;
    if (defaultTab !== null && defaultTab !== undefined) {
      return `${base}/${defaultTab}`;
    }
    return base;
  },
};

export const CoreBackendSSEMessages = {
  SITE_CONFIGURATION: 'site/configuration',
  CONTRACTS: 'site/contracts',
  SPARE_PARTS_PROVIDERS: 'site/sparePartsProviders',
  KANBAN_WORKFLOWS_STATE_SNAPSHOT: 'kanbanWorkflowStateSnapshot',
  NOTIFICATION: 'notification',
};

export const BackendSSEMessages = {
  SSE: (token: string): string => `${WAN_API_ROUTE_PREFIX}/sse/${token}`,
  SERVER_ACTIONS_LOG: (entityName: KnownKeysOf<RepositoryEntities>): string =>
    `repository/${entityName}/ServerActionLog`,
};

export const EntitiesBackendRoutes = {
  REPOSITORY_ACTION_LOG: (
    entityName: keyof RepositoryEntities,
    from: string,
    to?: string
  ): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/actions/log/${from}${to ? `/${to}` : ''}`,
  REPOSITORY_ACTION_EXECUTE: (entityName: KnownKeysOf<RepositoryEntities>): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/actions/execute`,
  REPOSITORY_FIX_ENTITY: (entityName: KnownKeysOf<RepositoryEntities>, entityId: string): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/entity/${entityId}/fix`,
  /*
   * Retrieving the repository entities can last a few seconds on the server side (as the sql query
   * retrieves a lot of data). For contexts where deltas can be processesd, it is better to get the
   * last cached version of these entities and then apply deltas. If not, the query may take
   * a few seconds to be completed.
   */
  REPOSITORY_ENTITIES: (entityName: KnownKeysOf<RepositoryEntities>, cached = false): string =>
    `${WAN_API_ROUTE_PREFIX}/repository/${entityName}/entities&cached=${cached}`,
};
