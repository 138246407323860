export function generateRange(initialValue: number, finalValue: number): number[] {
  const length = finalValue - initialValue + 1;
  return Array.from({ length }, (_, index) => initialValue + index);
}

export function getCurrentPage(originalPage: number, totalPages: number): number {
  if (originalPage > totalPages) {
    return 1;
  }
  return originalPage;
}

export function getTotalPages(totalEntry: number, pageSize: number): number {
  if (totalEntry <= pageSize) {
    return 1;
  }
  return Math.ceil(totalEntry / pageSize);
}

export function isCurrentPage(currentOffset: number, pageNumber: number): boolean {
  return currentOffset === pageNumber;
}
