import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { MPStoreDef } from '../../store/typings/store.js';
import { EMPTY_CONTACT_MODAL_STATE } from '../../store/store.js';

export function useOnNavigation($gs: GlobalStoreStateSelector<MPStoreDef>): void {
  const location = useLocation();

  const resetErrorStateOnNavigationAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
    $gs.$error
  );

  const resetModalStateOnNavigationAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      window.scrollTo(0, 0);
      actionDispatch.setValue(EMPTY_CONTACT_MODAL_STATE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
    $gs.$contactModal
  );

  const closeMobileCarFiltersAsyncEffect = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
    $gs.$cars.$isMobileFilterOpened
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resetErrorStateOnNavigationAsyncEffect();
  }, [resetErrorStateOnNavigationAsyncEffect]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resetModalStateOnNavigationAsyncEffect();
  }, [resetModalStateOnNavigationAsyncEffect]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    closeMobileCarFiltersAsyncEffect();
  }, [closeMobileCarFiltersAsyncEffect]);
}
