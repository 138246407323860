import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { formatPrice } from '@stimcar/libs-kernel';
import { useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import {
  getTotalRefurbishingOptionsPrice,
  MarketplaceFrontendRoutes,
} from '@stimcar/marketplace-libs-common';
import type { CarDetailsState, MPStoreDef } from '../../../store/typings/store.js';
import type { MarketplaceAppProps } from '../../App.js';
import { Image } from '../../components/common/image/Image.js';
import { ContactButton } from '../../components/contact/ContactButton.js';

interface UserSelectionSummaryProps extends MarketplaceAppProps {
  readonly isMobile?: boolean;
  readonly $: StoreStateSelector<MPStoreDef, CarDetailsState>;
}

export function UserSelectionSummary({ $, $gs, isMobile }: UserSelectionSummaryProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const car = useGetState($.$car);
  const selectedRefurbishingOptions = useGetState($.$selectedRefurbishingOptions);
  const refurbishingPrice = useMemo(
    () => getTotalRefurbishingOptionsPrice(car.defects, selectedRefurbishingOptions),
    [car.defects, selectedRefurbishingOptions]
  );
  const totalPrice = useMemo(
    () => car.publicPrice + refurbishingPrice,
    [car.publicPrice, refurbishingPrice]
  );
  return (
    <>
      <div
        className={`is-flex ${isMobile ? 'is-align-items-baseline is-justify-content-space-between mb-1' : 'is-align-items-center'}`}
      >
        <span className={`has-text-weight-bold is-size-${isMobile ? '4' : '2'}`}>
          {`${t('common.priceWithVAT', { price: formatPrice(Math.round(totalPrice)) })}`}
        </span>
        <ContactButton
          car={car}
          $gs={$gs}
          reason="offer"
          label={t('carDetails.makeAnOffer')}
          className={`${isMobile ? '' : 'ml-5'}`}
          selectedRefurbishingOptions={selectedRefurbishingOptions}
        />
      </div>
      <ContactButton
        car={car}
        $gs={$gs}
        label={t('carDetails.order')}
        reason="buy-with-refurbishing"
        selectedRefurbishingOptions={selectedRefurbishingOptions}
        className={`stimcar-blue-button-with-white-text${isMobile ? '' : ' ml-2'}`}
      />
    </>
  );
}

interface CarDetailsNavBarProps extends MarketplaceAppProps {
  readonly $: StoreStateSelector<MPStoreDef, CarDetailsState>;
}

export function CarDetailsNavBar({ $, $gs }: CarDetailsNavBarProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const bulmaTheme = useGetState($gs.$bulmaTheme);
  const isMobile = useScreenIsBulmaMobile($gs.$window);

  return (
    <nav
      role="navigation"
      aria-label={t('navBar.navBarAriaLabel')}
      className={`navbar marketplace-navbar is-fixed-top is-max-width-100vw${isMobile ? '' : ' py-1'}`}
    >
      <div className={`navbar-brand${isMobile ? ' is-justify-content-space-between' : ''}`}>
        {!isMobile && (
          <NavLink
            to={MarketplaceFrontendRoutes.CARS}
            aria-label={t('navBar.goBackAriaLabel')}
            className="navbar-item has-background-inherit"
          >
            <span className="icon is-large">
              <i
                className={`fas fa-arrow-left fa-2x has-text-${bulmaTheme === 'light' ? 'black' : 'white'}`}
              />
            </span>
          </NavLink>
        )}
        <NavLink to={MarketplaceFrontendRoutes.HOME} className="navbar-item has-background-inherit">
          <Image
            height="50px"
            width="180px"
            loading="eager"
            fetchPriority="high"
            className="stimcar-logo"
            alt={t('common.stimcarLogoAlt')}
            src={`stimcarlogo-${bulmaTheme}.svg`}
          />
        </NavLink>
        {isMobile && (
          <NavLink
            to={MarketplaceFrontendRoutes.CARS}
            aria-label={t('navBar.goBackAriaLabel')}
            className="navbar-item has-background-inherit"
          >
            <button className="button" type="button">
              {t('navBar.goBack')}
            </button>
          </NavLink>
        )}
      </div>
      {!isMobile && (
        <div className="navbar-end is-flex is-align-items-center mr-2">
          <UserSelectionSummary $={$} $gs={$gs} />
        </div>
      )}
    </nav>
  );
}
