import i18next from 'i18next';
import { isTruthy } from '@stimcar/libs-kernel';
import type { Customer } from '../../model/typings/customer.js';
import type { SpecificFields } from '../../model/typings/repository.js';

function getCustomerDisplayLabel({
  civility,
  firstName,
  lastName,
  individual,
  company,
}: Pick<
  SpecificFields<Customer>,
  'civility' | 'firstName' | 'lastName' | 'individual' | 'company'
>): string {
  if (!individual) {
    return company;
  }

  const civilityLabel =
    !isTruthy(civility) || civility === 'notset'
      ? ''
      : `${i18next.t(`common:civilities.${civility}`)} `;

  return `${civilityLabel} ${firstName} ${lastName}`;
}

export const customerHelpers = {
  getCustomerDisplayLabel,
};
