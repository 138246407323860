import React from 'react';

interface IconWithLabelProps {
  readonly label: string;
  readonly src: string;
  readonly alt: string;
  readonly height?: string;
  readonly width?: string;
  readonly labelSize?: string;
  readonly className?: string;
}

export function IconWithLabel({
  label,
  src,
  alt,
  width = '24',
  height = '24',
  labelSize = '7',
  className = '',
}: IconWithLabelProps): JSX.Element {
  return (
    <div className="is-flex is-align-items-center">
      <img src={src} alt={alt} width={width} height={height} loading="lazy" fetchPriority="low" />
      <span className={`ml-2 is-size-${labelSize} ${className}`}>{label}</span>
    </div>
  );
}
