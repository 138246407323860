import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '@stimcar/libs-kernel';

interface ContactSummaryTableRowProps {
  readonly label: string;
  readonly price: number;
  readonly hasTextBold?: boolean;
}

function ContactSummaryTableRow({
  label,
  price,
  hasTextBold,
}: ContactSummaryTableRowProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const formattedPrice = useMemo(
    () => t('common.priceWithVAT', { price: formatPrice(price, 2) }),
    [t, price]
  );
  return (
    <tr>
      <th
        className={`has-no-border pl-1 ${hasTextBold ? 'has-text-weight-bold' : 'has-text-weight-normal'}`}
      >
        {label}
      </th>
      <th
        className={`summary-table-price-cell has-no-border has-text-right pr-1 ${hasTextBold ? 'has-text-weight-bold' : 'has-text-weight-normal'}`}
      >
        {formattedPrice}
      </th>
    </tr>
  );
}

interface SummaryTableProps {
  readonly carLabel: string;
  readonly carPrice: number;
  readonly refurbishingPrice: number;
}

export function ContactSummaryTable({
  carLabel,
  carPrice,
  refurbishingPrice,
}: SummaryTableProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <table className="table is-fullwidth mb-0">
      <tfoot>
        <ContactSummaryTableRow price={carPrice} label={carLabel} />
        <ContactSummaryTableRow price={refurbishingPrice} label={t('defects.options')} />
        <ContactSummaryTableRow
          hasTextBold
          label={t('defects.total')}
          price={carPrice + refurbishingPrice}
        />
      </tfoot>
    </table>
  );
}
