import type { DetailedHTMLProps, FormEvent, InputHTMLAttributes } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  MarketplaceCar,
  MarketplaceContactCarCondition,
  MarketplaceContactReason,
  SelectedRefurbishingOptions,
} from '@stimcar/marketplace-libs-common';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import {
  getCarDetailsLabel,
  getTotalRefurbishingOptionsPrice,
  MarketplaceBackendRoutes,
} from '@stimcar/marketplace-libs-common';
import type { MPStoreDef } from '../../../store/typings/store.js';
import type { MarketplaceAppProps } from '../../App.js';
import { EMPTY_CONTACT_MODAL_STATE } from '../../../store/store.js';
import { NumberTag } from '../common/tags/NumberTag.js';
import './ContactButton.scss';
import { ContactSummaryTable } from './ContactSummaryTable.js';

// We use a simple regular expression to validate phone number
// we don't want to be too strict regarding the phone number
// User can enter :
// - numbers
// - '.', '-' or spaces
// - '+' because +33612345678 is a valid french phone number for example
const PHONE_NUMBER_REGEX = '^(\\s|[0-9.+\\-])*$';

const NAME_FORM_KEY = 'name';
const PHONE_FORM_KEY = 'phone';
const CITY_FORM_KEY = 'city';
const EMAIL_FORM_KEY = 'email';
const REQUEST_FORM_KEY = 'request';
const ADDITIONAL_INFORMATIONS_FORM_KEY = 'additionalInformations';
const CAR_RESEARCH_FORM_KEY = 'carResearch';
const CAR_WORK_FORM_KEY = 'carWork';
const BRAND_FORM_KEY = 'brand';
const MODEL_FORM_KEY = 'model';
const LICENSE_FORM_KEY = 'license';
const MILEAGE_FORM_KEY = 'mileage';
const DATE_OF_REGISTRATION_FORM_KEY = 'dateOfRegistration';
const CONDITION_FORM_KEY = 'condition';
const OFFER_AMOUNT_FORM_KEY = 'amount';
const OFFER_COMMENTS_FORM_KEY = 'offerComments';

const CONDITION_OPTIONS: readonly MarketplaceContactCarCondition[] = [
  'VERY_GOOD',
  'CORRECT',
  'AVERAGE',
];

interface WithLabelProps {
  readonly label: string;
  readonly formKey: string | undefined;
  readonly children: JSX.Element;
}

function WithLabel({ label, formKey, children }: WithLabelProps): JSX.Element {
  return (
    <>
      <label className="label mb-1" htmlFor={formKey}>
        {label}
      </label>
      {children}
    </>
  );
}

interface LabelledFormInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  readonly label: string;
  readonly leftIcon?: string;
}

function LabelledFormInput({
  label,
  leftIcon,
  ...inputProps
}: LabelledFormInputProps): JSX.Element {
  return (
    <WithLabel label={label} formKey={inputProps.id}>
      <div className={`control ${leftIcon ? 'has-icons-left' : ''}`}>
        <input
          name={inputProps.id}
          className={`input px-2 py-3${leftIcon ? ' with-left-icon' : ''}`}
          {...inputProps}
        />
        {leftIcon && (
          <span className="icon is-small is-left">
            <i className={`fas ${leftIcon}`} />
          </span>
        )}
      </div>
    </WithLabel>
  );
}

function RequestFormPart(): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <div className="field mb-2">
      <WithLabel formKey={REQUEST_FORM_KEY} label={t('contactForm.tellUsAboutYourRequest')}>
        <div className="control">
          <textarea
            required
            rows={3}
            className="textarea"
            id={REQUEST_FORM_KEY}
            name={REQUEST_FORM_KEY}
          />
        </div>
      </WithLabel>
    </div>
  );
}

function BuyFormPart(): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <div className="field">
      <WithLabel formKey={CAR_RESEARCH_FORM_KEY} label={t('contactForm.tellUsAboutYourResearch')}>
        <div className="control">
          <textarea
            required
            rows={3}
            className="textarea"
            id={CAR_RESEARCH_FORM_KEY}
            name={CAR_RESEARCH_FORM_KEY}
            placeholder={t('contactForm.exampleResearch')}
          />
        </div>
      </WithLabel>
    </div>
  );
}

function RefreshFormPart(): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <>
      <div className="columns mb-0">
        <div className="field column">
          <LabelledFormInput
            required
            id={BRAND_FORM_KEY}
            label={t(`contactForm.${BRAND_FORM_KEY}`)}
          />
        </div>
        <div className="field column">
          <LabelledFormInput
            required
            id={MODEL_FORM_KEY}
            label={t(`contactForm.${MODEL_FORM_KEY}`)}
          />
        </div>
      </div>
      <div className="field">
        <WithLabel formKey={CAR_WORK_FORM_KEY} label={t('contactForm.tellUsAboutTheWork')}>
          <div className="control">
            <textarea
              required
              rows={3}
              className="textarea"
              id={CAR_WORK_FORM_KEY}
              name={CAR_WORK_FORM_KEY}
              placeholder={t('contactForm.exampleWork')}
            />
          </div>
        </WithLabel>
      </div>
    </>
  );
}

function SellFormPart(): JSX.Element {
  const [t] = useTranslation('marketplace');
  return (
    <>
      <div className="columns mb-0">
        <div className="field column">
          <LabelledFormInput
            required
            id={BRAND_FORM_KEY}
            label={t(`contactForm.${BRAND_FORM_KEY}`)}
          />
        </div>
        <div className="field column">
          <LabelledFormInput
            required
            id={MODEL_FORM_KEY}
            label={t(`contactForm.${MODEL_FORM_KEY}`)}
          />
        </div>
      </div>
      <div className="columns mb-0">
        <div className="field column">
          <LabelledFormInput
            required
            id={LICENSE_FORM_KEY}
            label={t(`contactForm.${LICENSE_FORM_KEY}`)}
          />
        </div>
        <div className="field column">
          <LabelledFormInput
            required
            type="number"
            id={MILEAGE_FORM_KEY}
            label={t(`contactForm.${MILEAGE_FORM_KEY}`)}
          />
        </div>
      </div>
      <div className="columns mb-0">
        <div className="field column">
          <LabelledFormInput
            required
            type="date"
            id={DATE_OF_REGISTRATION_FORM_KEY}
            label={t(`contactForm.${DATE_OF_REGISTRATION_FORM_KEY}`)}
          />
        </div>
        <div className="field column">
          <WithLabel formKey={CONDITION_FORM_KEY} label={t('contactForm.condition')}>
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select id={CONDITION_FORM_KEY} name={CONDITION_FORM_KEY} defaultValue="CORRECT">
                  {CONDITION_OPTIONS.map((option) => (
                    <option key={option} value={option}>
                      {t(`contactForm.conditionOptions.${option}`)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </WithLabel>
        </div>
      </div>
    </>
  );
}

interface CarFormPartProps {
  readonly $car: StoreStateSelector<MPStoreDef, MarketplaceCar>;
  readonly $selectedRefurbishingOptions: StoreStateSelector<
    MPStoreDef,
    SelectedRefurbishingOptions
  >;
}

function RefurbishingFormPart({
  $car,
  $selectedRefurbishingOptions,
}: CarFormPartProps): JSX.Element {
  const car = useGetState($car);
  const [t] = useTranslation('marketplace');
  const selectedRefurbishingOptions = useGetState($selectedRefurbishingOptions);
  const carLabel = useMemo(() => getCarDetailsLabel(car), [car]);
  const refurbishingPrice = useMemo(
    () => getTotalRefurbishingOptionsPrice(car.defects, selectedRefurbishingOptions),
    [car.defects, selectedRefurbishingOptions]
  );
  return (
    <div className="mb-5">
      <div className="mb-2 is-flex is-align-items-center">
        <NumberTag label={1} />
        <h2 className="title is-6 pl-3">{t('contactForm.orderTitle')}</h2>
      </div>
      <ContactSummaryTable
        carLabel={carLabel}
        carPrice={car.publicPrice}
        refurbishingPrice={refurbishingPrice}
      />
      <hr />
    </div>
  );
}

function OfferFormPart({ $car, $selectedRefurbishingOptions }: CarFormPartProps): JSX.Element {
  const [t] = useTranslation('marketplace');
  const car = useGetState($car);
  const selectedRefurbishingOptions = useGetState($selectedRefurbishingOptions);
  const totalPrice = useMemo(
    () =>
      car.publicPrice + getTotalRefurbishingOptionsPrice(car.defects, selectedRefurbishingOptions),
    [car.defects, car.publicPrice, selectedRefurbishingOptions]
  );
  return (
    <div className="mb-5">
      <div className="mb-3 is-flex is-align-items-center">
        <NumberTag label={2} />
        <h2 className="title is-6 pl-3">{t('contactForm.offerTitle')}</h2>
      </div>
      <p className="subtitle is-size-6 mb-5">{t('contactForm.offerRules')}</p>
      <div className="is-flex is-align-items-center mb-3">
        <label className="label pr-3 mb-0" htmlFor={OFFER_AMOUNT_FORM_KEY}>
          {t('contactForm.offerAmount')}
        </label>
        <div className="control has-icons-right">
          <input
            min={0}
            required
            type="number"
            defaultValue={totalPrice}
            id={OFFER_AMOUNT_FORM_KEY}
            name={OFFER_AMOUNT_FORM_KEY}
            className="input px-2 py-3 pr-5 has-text-right"
          />
          <span className="icon is-small is-right">
            <i className="fas fa-euro-sign" />
          </span>
        </div>
      </div>
      <div className="field">
        <WithLabel formKey={OFFER_COMMENTS_FORM_KEY} label={t('contactForm.offerComments')}>
          <div className="control">
            <textarea
              rows={2}
              className="textarea"
              id={OFFER_COMMENTS_FORM_KEY}
              name={OFFER_COMMENTS_FORM_KEY}
            />
          </div>
        </WithLabel>
      </div>
      <hr />
    </div>
  );
}

function ContactModal({ $gs }: MarketplaceAppProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const $ = $gs.$contactModal;

  const closeModal = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(EMPTY_CONTACT_MODAL_STATE);
    },
    [],
    $
  );

  const reason = useGetState($.$reason);

  const handleSubmit = useActionCallback(
    async ({ httpClient, actionDispatch, getState }, submitEvent: FormEvent<HTMLFormElement>) => {
      submitEvent.preventDefault();

      const form = submitEvent.target as HTMLFormElement;
      const formData = new FormData(form);
      if (reason === 'buy-with-refurbishing' || reason === 'offer') {
        const car = nonnull(getState().car);
        const selectedRefurbishingOptions = nonnull(getState().selectedRefurbishingOptions);
        formData.set('car', getCarDetailsLabel(car));
        formData.set('carPrice', car.publicPrice.toString());
        formData.set('defects', JSON.stringify(car.defects));
        formData.set('selectedRefurbishingOptions', JSON.stringify(selectedRefurbishingOptions));
      }
      const mailContent = Object.fromEntries(formData);

      try {
        await httpClient.httpPostAsJSON(MarketplaceBackendRoutes.SEND_CONTACT_MAIL, {
          ...mailContent,
          reason,
        });
        form.reset();
        actionDispatch.setValue({
          ...EMPTY_CONTACT_MODAL_STATE,
          success: true,
        });
      } catch {
        actionDispatch.setProperty('error', true);
      }
    },
    [reason],
    $
  );

  const isMobile = useScreenIsBulmaMobile($gs.$window);

  const modalTitle = useMemo(() => {
    if (reason === 'buy-with-refurbishing') {
      return t('contactForm.orderTitle');
    }
    if (reason === 'offer') {
      return t('contactForm.offerTitle');
    }
    if (isMobile) {
      return t('contactForm.shortTitle');
    }
    if (reason === 'buy') {
      return t('contactForm.buyTitle');
    }
    if (reason === 'sell') {
      return t('contactForm.sellTitle');
    }
    if (reason === 'refresh') {
      return t('contactForm.refreshTitle');
    }
    return t('contactForm.shortTitle');
  }, [isMobile, reason, t]);

  const isActive = useGetState($.$active);
  const error = useGetState($.$error);

  const errorLabel = useMemo(
    () => (isMobile ? t('contactForm.shortErrorLabel') : t('contactForm.errorLabel')),
    [isMobile, t]
  );

  return (
    <>
      {isActive && (
        <div className="modal contact-modal is-active has-text-left">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title is-size-5">{modalTitle}</p>
              <button
                type="button"
                className="delete"
                onClick={closeModal}
                aria-label={t('contactForm.closeModalAriaLabel')}
              />
            </header>
            <form onSubmit={handleSubmit}>
              <section className="modal-card-body py-4 px-5">
                {(reason === 'buy-with-refurbishing' || reason === 'offer') && (
                  <RefurbishingFormPart
                    $car={$.$car.asDefined()}
                    $selectedRefurbishingOptions={$.$selectedRefurbishingOptions.asDefined()}
                  />
                )}
                {reason === 'offer' && (
                  <OfferFormPart
                    $car={$.$car.asDefined()}
                    $selectedRefurbishingOptions={$.$selectedRefurbishingOptions.asDefined()}
                  />
                )}
                {reason === 'buy-with-refurbishing' || reason === 'offer' ? (
                  <div className="mb-4 is-flex is-align-items-center">
                    <NumberTag label={reason === 'buy-with-refurbishing' ? 2 : 3} />
                    <h2 className="title is-6 pl-3">{t('contactForm.contactInformations')}</h2>
                  </div>
                ) : (
                  <h2 className="title is-6">{t('contactForm.contactInformations')}</h2>
                )}
                <div className="columns mb-0">
                  <div className={`field column ${isMobile ? 'mb-0 pb-0 pt-1' : 'py-1'}`}>
                    <LabelledFormInput
                      required
                      id={NAME_FORM_KEY}
                      label={t(`contactForm.${NAME_FORM_KEY}`)}
                    />
                  </div>
                  <div className={`field column ${isMobile ? 'pt-1' : 'py-1'}`}>
                    <LabelledFormInput
                      required
                      id={CITY_FORM_KEY}
                      label={t(`contactForm.${CITY_FORM_KEY}`)}
                    />
                  </div>
                </div>
                <div className="columns mb-0">
                  <div className={`field column mb-1 ${isMobile ? ' b-0 pb-0 pt-1' : 'py-1'}`}>
                    <LabelledFormInput
                      required
                      type="email"
                      leftIcon="fa-envelope"
                      id={EMAIL_FORM_KEY}
                      label={t(`contactForm.${EMAIL_FORM_KEY}`)}
                    />
                  </div>
                  <div className={`field column ${isMobile ? 'pt-1' : 'py-1'}`}>
                    <LabelledFormInput
                      required
                      type="tel"
                      leftIcon="fa-phone"
                      id={PHONE_FORM_KEY}
                      pattern={PHONE_NUMBER_REGEX}
                      label={t(`contactForm.${PHONE_FORM_KEY}`)}
                    />
                  </div>
                </div>
                {reason === 'request' && <RequestFormPart />}
                {reason === 'buy' && <BuyFormPart />}
                {reason === 'refresh' && <RefreshFormPart />}
                {reason === 'sell' && <SellFormPart />}
                {reason === 'buy-with-refurbishing' && (
                  <div className="field">
                    <WithLabel
                      formKey={ADDITIONAL_INFORMATIONS_FORM_KEY}
                      label={t('contactForm.additionalInformations')}
                    >
                      <div className="control">
                        <textarea
                          rows={2}
                          className="textarea"
                          id={ADDITIONAL_INFORMATIONS_FORM_KEY}
                          name={ADDITIONAL_INFORMATIONS_FORM_KEY}
                        />
                      </div>
                    </WithLabel>
                  </div>
                )}
              </section>
              <footer className="modal-card-foot py-4 px-5">
                <div className="buttons mb-0">
                  <button
                    type="submit"
                    className="button stimcar-white-button-with-blue-text-and-border"
                  >
                    {t('contactForm.send')}
                  </button>
                </div>
                {error && <div className="notification is-danger py-2 px-3 ml-4">{errorLabel}</div>}
              </footer>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

interface ContactButtonProps extends MarketplaceAppProps {
  readonly label?: string;
  readonly className?: string;
  readonly car?: MarketplaceCar;
  readonly reason: MarketplaceContactReason;
  readonly selectedRefurbishingOptions?: SelectedRefurbishingOptions;
}

export function ContactButton({
  $gs,
  car,
  label,
  reason,
  className = '',
  selectedRefurbishingOptions,
}: ContactButtonProps): JSX.Element {
  const [t] = useTranslation('marketplace');

  const openContactModal = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue({
        active: true,
        reason,
        success: false,
        error: false,
        car,
        selectedRefurbishingOptions,
      });
    },
    [reason, car, selectedRefurbishingOptions],
    $gs.$contactModal
  );

  return (
    <>
      <button type="button" onClick={openContactModal} className={`button ${className}`}>
        {label ?? t('contactForm.beContacted')}
      </button>
      <ContactModal $gs={$gs} />
    </>
  );
}
